import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(customer_id) {
		/* const params = {
			order: 'desc',
			order_by: 'name',
			offset: 0,
			limit: 0,
		} */
		const url = API_ROUTES.brand.get.replace(':customer_id', customer_id)
		const { data } = await useJwt.get(url)

		return data
	},
	async find(customer_id, id) {
		const url = API_ROUTES.brand.find.replace(':customer_id', customer_id).replace(':id', id)
		const { data } = await useJwt.get(url)

		return data
	},
	async save(customer_id, id, data) {
		const url = API_ROUTES.brand.save
			.replace(':customer_id', customer_id)
			.replace(':id', id)
		return useJwt.put(url, data)
	},
	async delete(customer_id, id) {
		const url = API_ROUTES.brand.save
			.replace(':customer_id', customer_id)
			.replace(':id', id)
		return useJwt.delete(url)
	},
	async update(customer_id, id, data) {
		const url = API_ROUTES.brand.update
			.replace(':customer_id', customer_id)
			.replace(':id', id)
		return useJwt.patch(url, data)
	},

	async getAllCountry() {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 0,
			'filters[0][field]': 'id',
			'filters[0][operator]': '=',
			'filters[0][value]': 1,
		}
		const { data } = await useJwt.get(API_ROUTES.country.get, params)

		return data
	},
	async getAllDepartament() {
		const params = {
			order: 'asc',
			order_by: 'id',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'country_id',
			'filters[0][operator]': '=',
			'filters[0][value]': 1,
		}
		const { data } = await useJwt.get(API_ROUTES.departament.get, params)

		return data
	},
	async getAllCitiesByDepartament(departamentId) {
		const params = {
			order: 'asc',
			order_by: 'id',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'department_id',
			'filters[0][operator]': '=',
			'filters[0][value]': departamentId,
		}
		const { data } = await useJwt.get(API_ROUTES.city.get, params)

		return data
	},
}
